<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <!-- Table Top -->
        <b-row
          v-if="isProducto"
          class="m-2"
        >
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start pb-2 mb-md-0"
          >
            <h2><strong>{{ almacen.numeroComprobante }}</strong> - {{ almacen.clienteDato }} <strong>[TOTAL: S/.{{ almacen.total }}] [FACTURADO: S/.{{ sumatoriaControlFise }}] [RESTANTA: S/.{{ almacen.total - sumatoriaControlFise }}]</strong> </h2>

          </b-col>
          <b-col
            v-for="(detalle, index) in almacen.detalleVenta"
            :key="index"
            md="4"
          >
            <strong> {{ detalle.productoDato }} [cantidad: {{ detalle.cantidad }}] [precio: S/{{ parseFloat(detalle.precio).toFixed(2) }}] </strong>
          </b-col>
        </b-row>
        <b-row class="m-2">
          <b-col
            cols="12"
            md="12"
            class="mt-2"
          >
            <b-tabs>
              <b-tab
                v-if="almacen.tipoPago !== null && almacen.tipoPago !== 'COMPLETO'"
                active
                title="Control Fise"
              >
                <b-row class="m-2">
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Mostrando</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>registros</label>
                  </b-col>

                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="query"
                        class="d-inline-block mr-1"
                        placeholder="Buscar..."
                      />
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="2"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <strong class="text-muted"> Vales Fise ({{ of }})</strong>
                  </b-col>
                  <!-- <b-col md="2">
                    <b-form-group>
                      <b-form-input
                        v-model="totalAll"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-table
                  ref="refUserListTable"
                  class="position-relative"
                  :items="items"
                  responsive
                  :fields="fields"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="No se encontraron registros"
                  :busy="isBusy"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner
                        variant="primary"
                        class="align-middle"
                      />
                      <strong class="ml-1">Cargando...</strong>
                    </div>
                  </template>
                  <template #cell(razonSocial)="data">
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="32"
                          :src="`data:image/jpeg;base64,${data.item.foto}`"
                          :text="avatarText(`${data.item.razonSocial}`)"
                          :variant="`light-${getColorAvatar()}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.razonSocial }}
                      </span>
                      <small class="text-muted">{{ data.item.representanteLegal }}</small>
                      <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
                    </b-media>
                  </template>
                  <!-- Column: estado_venta  @input="changeStockMinimo(data.item)" -->
                  <template #cell(estadoFise)="data">
                    <b-badge
                      v-if="data.item.estadoFise == 'PROCESADO'"
                      variant="success"
                    >
                      PROCESADO
                    </b-badge>
                    <b-badge
                      v-if="data.item.estadoFise == 'PENDIENTE'"
                      variant="primary"
                    >
                      PENDENTE
                    </b-badge>

                  </template>
                  <template #cell(total)="data">
                    <b-form-input
                      v-model="data.item.total"
                      :disabled="data.item.estadoFise == 'PROCESADO'"
                      style="max-width: 100px;"
                      @keyup.enter="getTotalFise(data.item)"
                    />
                  </template>
                  <template #cell(montoFise)="data">
                    <b-form-input
                      v-model="data.item.montoFise"
                      :disabled="data.item.estadoFise == 'PROCESADO'"
                      style="max-width: 100px;"
                      @keyup.enter="getMontoFise(data.item)"
                    />
                  </template>
                  <template #cell(codigo)="data">
                    <b-form-input
                      v-model="data.item.codigo"
                      :disabled="data.item.estadoFise == 'PROCESADO'"
                      style="max-width: 120px;"
                      @keyup.enter="getCodigoFise(data.item,data.index)"
                    />
                  </template>
                  <template #cell(nombreBeneficiario)="data">
                    <b-form-input
                      v-model="data.item.nombreBeneficiario"
                      :disabled="true"
                    />
                  </template>
                  <template #cell(dniBeneficiario)="data">
                    <b-form-input
                      v-model="data.item.dniBeneficiario"
                      :disabled="data.item.estadoFise == 'PROCESADO'"
                      style="max-width: 100px;"
                      @keyup.enter="getSearchDocumentCliente(data.item)"
                    />
                  </template>
                  <template #cell(productoTemplate)="data">
                    <v-select
                      v-model="data.item.producto"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="descripcion"
                      :disabled="data.item.estadoFise == 'PROCESADO'"
                      :options="selectProducto"
                      @input="changeProductoFise(data)"
                    />
                  </template>
                  <template #cell(direccionBeneficiario)="data">
                    <!-- <b-form-input
                      v-model="data.item.direccionBeneficiario"
                      :disabled="true"
                    /> -->
                    <div>{{ data.item.direccionBeneficiario }}</div>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(acciones)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="data.item.estadoFise == 'PENDIENTE'"
                        @click="getProcesar(data)"
                      >
                        <feather-icon icon="ClipboardIcon" />
                        <span class="align-middle ml-50">Procesar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="data.item.estadoFise == 'PENDIENTE'"
                        @click="getDelete(data)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Eliminar</span>
                      </b-dropdown-item>
                      <!-- <b-dropdown-item
                        v-if="data.item.estadoFise == 'PROCESADO' && data.item.generaVenta.repuestaOse == null"
                        target="_blank"
                        :href="data.item.generaVenta.repuestaOse == null ?'':data.item.generaVenta.repuestaOse.rutaPdf"
                      >
                        <feather-icon icon="ClipboardIcon" />
                        <span class="align-middle ml-50">{{ data.item.generaVenta.numeroComprobante }}</span>
                      </b-dropdown-item> -->
                    </b-dropdown>
                  </template>

                </b-table>
                <div>
                  <b-row class="mx-2 mb-2">

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="page"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-tab>
              <b-tab
                title="Facturacion pendiente"
              >
                <b-row class="m-2">
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Mostrando</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>registros</label>
                  </b-col>

                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="query"
                        class="d-inline-block mr-1"
                        placeholder="Buscar..."
                      />
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="2"
                  >
                    <b-button
                      variant="primary"
                      @click="openModalControlFise('new')"
                    >
                      <span class="text-nowrap">Nuevo</span>
                    </b-button>
                  </b-col>
                  <!-- <b-col md="2">
                    <b-form-group>
                      <b-form-input
                        v-model="totalAll"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-table
                  ref="refUserListTable"
                  class="position-relative"
                  :items="itemsFacturacion"
                  responsive
                  :fields="fieldsFacturacion"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="No se encontraron registros"
                  :busy="isBusyFacturacion"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner
                        variant="primary"
                        class="align-middle"
                      />
                      <strong class="ml-1">Cargando...</strong>
                    </div>
                  </template>
                  <template #cell(razonSocial)="data">
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="32"
                          :src="`data:image/jpeg;base64,${data.item.foto}`"
                          :text="avatarText(`${data.item.razonSocial}`)"
                          :variant="`light-${getColorAvatar()}`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.razonSocial }}
                      </span>
                      <small class="text-muted">{{ data.item.representanteLegal }}</small>
                      <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
                    </b-media>
                  </template>
                  <!-- Column: estado_venta  @input="changeStockMinimo(data.item)" -->
                  <template #cell(comprobanteTemplate)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-small-4 font-weight-bolder">
                          {{ data.item.generaVenta.numeroComprobante }}
                        </div>
                        <div class="font-small-3 text-muted">
                          {{ data.item.generaVenta.fechaVenta }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(clienteTemplate)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-small-3 font-weight-bolder">
                          {{ data.item.generaVenta.clienteDato.split('|')[0] }}
                        </div>
                        <div class="font-small-2 text-muted">
                          {{ data.item.generaVenta.clienteDato.split('|')[2].length==11?'RUC':'DNI' }}: {{ data.item.generaVenta.clienteDato.split('|')[2] }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(clienteFinalTemplate)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          v-if="data.item.generaVenta.clienteFinal !== null"
                          class="font-small-3 font-weight-bolder"
                        >
                          {{ data.item.generaVenta.clienteFinal.nombreComercial }}
                        </div>
                        <div
                          v-if="data.item.generaVenta.clienteFinal !== null"
                          class="font-small-2 text-muted"
                        >
                          {{ data.item.generaVenta.clienteFinal.documento.length==11?'RUC':'DNI' }}: {{ data.item.generaVenta.clienteFinal.documento }}
                        </div>
                        <div
                          v-if="data.item.generaVenta.clienteFinal === null"
                          class="font-small-4 text-muted"
                        >
                          <strong>No registro Cliente</strong>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(totalFormat)="data">
                    {{ formatearMoneda(data.item.generaVenta.total) }}
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(acciones)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="data.item.estadoFise == 'PENDIENTE'"
                        @click="getProcesar(data)"
                      >
                        <feather-icon icon="ClipboardIcon" />
                        <span class="align-middle ml-50">Procesar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="data.item.estadoFise == 'PENDIENTE'"
                        @click="getDelete(data)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Eliminar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="data.item.estadoFise == 'PROCESADO'"
                        target="_blank"
                        :href="data.item.generaVenta.repuestaOse == null ?'':data.item.generaVenta.repuestaOse.rutaPdf"
                      >
                        <feather-icon icon="ClipboardIcon" />
                        <span class="align-middle ml-50">{{ data.item.generaVenta.numeroComprobante }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                </b-table>
                <div>
                  <b-row class="mx-2 mb-2">

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Mostrando {{ fromFacturacion }} a {{ toFacturacion }} de {{ ofFacturacion }} registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="pageFacturacion"
                        :total-rows="totalItemsFacturacion"
                        :per-page="perPageFacturacion"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

      </div>
    </b-card>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      title=""
      no-close-on-backdrop
      no-close-on-esc
      style="max-width: 90%;"
    >
      <div class="d-block">
        <h4 class="text-center">
          NUEVA FACTURACION PENDIENTE</h4>
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Tipo de Documento"
                      rules="required"
                    >
                      <b-form-group
                        label="Tipo de Documento"
                        label-for="tipoDocumento"
                      >
                        <v-select
                          v-model="formData.tipoDocumento"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="descripcion"
                          :options="selectTipoDocumento"
                          :state="getValidationState(validationContext)"
                          @input="getSeries()"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Serie"
                      rules="required"
                    >
                      <b-form-group
                        label="Serie"
                        label-for="serie"
                      >
                        <v-select
                          v-model="formData.serie"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="serie"
                          :options="selectSerie"
                          :state="getValidationState(validationContext)"
                          @input="getNroComprobante()"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Nro Comprobante"
                      rules="required"
                    >
                      <b-form-group
                        label="Nro Comprobante"
                        label-for="nro_comprobante"
                      >
                        <b-form-input
                          v-model="formData.nro_comprobante"
                          :state="getValidationState(validationContext)"
                          readonly
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="RUC / DNI"
                      rules="required"
                    >
                      <b-form-group
                        label="RUC / DNI"
                        label-for="documentoCliente"
                      >
                        <b-input-group>
                          <b-form-input
                            ref="documentoCliente"
                            v-model="formData.documentoCliente"
                            type="number"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append>
                            <b-button
                              size="sm"
                              variant="outline-info"
                              @click.prevent="getSearchDocument('R')"
                            >
                              RUC
                            </b-button>
                            <b-button
                              size="sm"
                              variant="outline-info"
                              @click.prevent="getSearchDocument('D')"
                            >
                              DNI
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="clienteFinal"
                    >
                      <b-form-group
                        label="Cliente Final"
                        label-for="clienteFinal"
                      >
                        <v-select
                          v-model="formData.clienteFinal"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nombreComercial"
                          :options="selectClienteFinal"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Razon Social"
                      rules="required"
                    >
                      <b-form-group
                        label="Razon Social"
                        label-for="razon_social"
                      >
                        <b-form-input
                          v-model="formData.razon_social"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Direccion"
                      rules="required"
                    >
                      <b-form-group
                        label="Direccion"
                        label-for="direccion"
                      >
                        <b-form-input
                          v-model="formData.direccion"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="email"
                    >
                      <b-form-group
                        label="Email"
                        label-for="Email"
                      >
                        <b-form-input
                          v-model="formData.email"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mb-md-0"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="observacion"
                    >
                      <b-form-group
                        label="Observacion"
                        label-for="observacion"
                      >
                        <b-form-input
                          v-model="formData.observacion"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    class="mb-1 mt-2 mb-md-0"
                  >
                    <b-table
                      ref="refUserListTable"
                      class="position-relative"
                      :items="itemsDetalle"
                      responsive
                      :fields="fieldsDetalle"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      show-empty
                      empty-text="No se encontraron registros"
                      :busy="isBusy"
                    >
                      <!-- Column: precio -->
                      <template #cell(productoNombre)="data">
                        <b-form-input
                          v-if="!data.item.isService"
                          v-model="data.item.productoNombre"
                          :disabled="true"
                          size="sm"
                        />
                        <b-form-input
                          v-if="data.item.isService"
                          v-model="data.item.productoNombre"
                          size="sm"
                        />
                      </template>
                      <template #cell(inputUnidadMedida)="data">
                        <v-select
                          v-model="data.item.unidadMedida"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="descripcion"
                          :options="[data.item.unidadMedida]"
                          disabled
                          class="select-size-sm"
                        />
                      </template>
                      <!-- Column: precio -->
                      <template #cell(inputPrecio)="data">
                        <b-form-input
                          v-model="data.item.precio"
                          size="sm"
                          type="number"
                          step="any"
                          :disabled="true"
                          @input="changeCantidadPrecio(data.item,'P')"
                        />
                      </template>
                      <!-- Column: Cantidad -->
                      <template #cell(inputCantidad)="data">
                        <b-form-input
                          v-model="data.item.cantidad"
                          type="number"
                          size="sm"
                          step="any"
                          min="1"
                          :max="data.item.cantidadMax"
                          @input="changeCantidadPrecio(data.item,'C')"
                        />
                      </template>
                      <!-- Column: Subtotal -->
                      <template #cell(inputSubtotal)="data">
                        <b-form-input
                          v-model="data.item.subtotal"
                          readonly
                          size="sm"
                        />
                      </template>
                      <!-- Column: Actions -->
                      <template #cell(acciones)="data">
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >

                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click="deleteDetalleFacturacion(data.item)"
                          >
                            <feather-icon icon="XSquareIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  />
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mt-1 mb-md-0"
                  >
                    <b-form-group
                      label="SubTotal"
                      label-for="subtotal"
                    >
                      <b-form-input
                        v-model="formData.subTotal"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mt-1 mb-md-0"
                  >
                    <b-form-group
                      label="Igv"
                      label-for="igv"
                    >
                      <b-form-input
                        v-model="formData.igv"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-1 mt-1 mb-md-0"
                  >
                    <b-form-group
                      label="Total"
                      label-for="total"
                    >
                      <b-form-input
                        v-model="formData.total"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  class="text-center"
                  align-v="center"
                >
                  <b-col
                    cols="12"
                    md="4"
                  />
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-button
                      variant="primary"
                      class="btn-icon"
                      type="submit"
                      size="lg"
                    >
                      Guardar
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  />
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge, BTabs, BTab, BButton, BCardBody, BForm, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,

    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    BTabs,
    BTab,
    BButton,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    isBusyFacturacion: false,
    rangeDate: null,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    almacen: null,
    sortDesc: false,
    permisoAlmacen: false,
    isProducto: false,
    sortDirection: 'asc',
    query: '',
    queryFacturacion: '',
    totalAll: '',
    items: [],
    itemsFacturacion: [],
    itemsDetalle: [],
    selectProducto: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'idControlFise', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'fecha', label: 'FECHA', sortable: false, thStyle: { width: '7%' } },
      { key: 'codigo', label: 'CODIGO', sortable: false, thStyle: { width: '10%' } },
      { key: 'nombreBeneficiario', label: 'BENEFICIARIO', sortable: false, thStyle: { width: '20%' } },
      { key: 'dniBeneficiario', label: 'DNI', sortable: false, thStyle: { width: '10%' } },
      { key: 'productoTemplate', label: 'PRODUCTO', sortable: false, thStyle: { width: '20%' } },
      // { key: 'nroComprobante', label: 'Nro Comprobante' },
      // { key: 'direccionBeneficiario', label: 'DIRECCION', sortable: false, thStyle: { width: '11%' } },
      { key: 'estadoFise', label: 'ESTADO', sortable: false, thStyle: { width: '5%' } },
      { key: 'montoFise', label: 'MONTO FISE', sortable: false, thStyle: { width: '5%' } },
      { key: 'total', label: 'TOTAL', sortable: false, thStyle: { width: '5%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
      // { key: 'acciones' },
    ],
    fieldsFacturacion: [
      { key: 'idControlFise', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'comprobanteTemplate', label: 'Comprobante', sortable: true },
      { key: 'clienteTemplate', label: 'Cliente', sortable: true, tdClass: 'w180', thClass: 'w180' },
      { key: 'clienteFinalTemplate', label: 'Cliente Final', sortable: true, tdClass: 'w180', thClass: 'w180' },
      // { key: 'numeroComprobante', label: 'Nro.Comprobante', sortable: true },
      // { key: 'formatoPago.descripcion', label: 'F. Pago', sortable: true, tdClass: 'text-center', thClass: 'text-center' },
      { key: 'generaVenta.tipoVenta.descripcion', label: 'T. Venta', sortable: true },
      { key: 'totalFormat', label: 'Total', sortable: true },
      { key: 'acciones', thStyle: { width: '5%' } },
      // { key: 'acciones' },
    ],
    fieldsDetalle: [
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '18%' } },
      { key: 'inputPrecio', label: 'Precio', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '14%' } },
      { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ],
    blankData: {
      fechaVenta: null,
      fechaVencimiento: null,
      vendedor: 0,
      tipoVenta: { descripcion: 'CONTADO', estado: true, tipoVentaId: 2 },
      formaPago: { descripcion: 'EFECTIVO', estado: true, formaPagoId: 1 },
      formaPrint: { descripcion: 'A4' },
      tipoDocumento: 0,
      serie: 0,
      igv: 0,
      subTotal: 0,
      total: 0,
      nro_comprobante: '',
      razon_social: 'CLIENTE VARIOS',
      documentoCliente: '00000000',
      direccion: '-',
      efectivo: 0,
      vuelto: 0,
      observacion: '',
      email: '',
      tipoPago: { descripcion: 'COMPLETO' },
      clienteFinal: null,
    },
    formData: null,
    subTotal: 0,
    selectTipoDocumento: [],
    getValidationState: null,
    refFormObserver: null,
    resetForm: null,
    selectSerie: [],
    required,
    pageFacturacion: 1,
    perPageFacturacion: 10,
    totalRowsFacturacion: 0,
    totalItemsFacturacion: 0,
    totalPageFacturacion: 0,
    fromFacturacion: 0,
    toFacturacion: 0,
    ofFacturacion: 0,
    resumenControlFise: [],
    sumatoriaControlFise: 0,
    selectClienteFinal: [],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
        this.listarRegistrosFacturacion()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
        this.listarRegistrosFacturacion()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
        this.listarRegistrosFacturacion()
      },
    },
  },
  created() {
    const formValidationMethods = formValidation(this.resetData)
    this.refFormObserver = formValidationMethods.refFormObserver
    this.getValidationState = formValidationMethods.getValidationState
    this.resetForm = formValidationMethods.resetForm
  },
  mounted() {
    this.formData = JSON.parse(JSON.stringify(this.blankData))
    this.almacen = this.$route.params.venta
    this.listarRegistros()
    this.listarRegistrosFacturacion()
  },
  methods: {
    getPrintPdf(data) {
      if (data.item.repuestaOse !== null) {
        console.log(data.item.repuestaOse.rutaPdf)
      }
    },
    async listarRegistros() {
      // const dataUser = JSON.parse(localStorage.getItem('userData'))
      console.log(this.$route.params.venta)
      this.isProducto = false
      this.isBusy = true
      this.selectProducto = []
      await store
        .dispatch('comercial/CONTROL_FISE_FIND_ALL', {
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: '',
          venta: this.$route.params.venta.ventaId,
          tipo: 'FISE',
          cliente_id: this.$route.params.venta.clienteId,
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
          this.items.forEach((element, index) => {
            if (index === 0) {
              this.almacen = element.origenVenta
            }
            this.items[index].isActive = false
            // element.origenVenta.detalleVenta.forEach(detalle => {
            //   if (detalle.fise) {
            //     this.items[index].total = detalle.precio
            //   }
            // })
            this.items[index].producto = { idProducto: element.idProducto, descripcion: element.detalle }
          })
          this.almacen.detalleVenta.forEach(detalle => {
            if (detalle.fise) {
              this.selectProducto.push({ idProducto: detalle.productoId, descripcion: detalle.productoDato, precio: detalle.precio })
            }
          })
          this.items = this.items.filter(item => item.tipo === 'FISE')
          this.sumatoriaControlFise = response.sumatoria
          this.isProducto = true
          // this.almacen = response.items[0]
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    async listarRegistrosFacturacion() {
      // const dataUser = JSON.parse(localStorage.getItem('userData'))
      console.log(this.$route.params.venta)
      this.isBusyFacturacion = true
      await store
        .dispatch('comercial/CONTROL_FISE_FIND_ALL', {
          limit: this.perPageFacturacion,
          query: this.queryFacturacion,
          page: this.pageFacturacion,
          sortBy: '',
          venta: this.$route.params.venta.ventaId,
          tipo: 'FACTURACION',
          cliente_id: this.$route.params.venta.clienteId,
        })
        .then(response => {
          this.itemsFacturacion = response.items
          this.totalRowsFacturacion = response.totalRows
          this.totalItemsFacturacion = response.totalItems
          this.totalPageFacturacion = response.totalPage
          this.fromFacturacion = this.perPageFacturacion * (this.pageFacturacion - 1) + (this.totalRowsFacturacion ? 1 : 0)
          this.toFacturacion = this.perPageFacturacion * (this.page - 1) + this.totalRowsFacturacion
          this.ofFacturacion = this.totalItemsFacturacion
          this.resumenControlFise = response.resumen
          this.sumatoriaControlFise = response.sumatoria
          this.selectClienteFinal = response.cliente
          // this.almacen = response.items[0]
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusyFacturacion = false
    },
    openForm(type) {
      // this.formType = type
      // this.isToggleSidebar = true
      console.log(type)
      this.$router.push({ name: 'rt-logistica-compraform' })
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data.item
    },
    async changeStockMinimo(data) {
      this.items.forEach((element, index) => {
        if (element.idStock === data.idStock) {
          this.items[index].isActive = true
        }
      })
      await store
        .dispatch('logistica/STOCK_UPDATE', data)
        .then(response => {
          console.log(response)
          //   this.items.forEach((element, index) => {
          //     if (element.idStock === response.idStock) {
          //       this.items[index].isActive = false
          //     }
          //   })
          this.showToast('Modificacion de registro', 'se actualizo el stock minimo', 'success')
          this.listarRegistros()
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
          this.items.forEach((element, index) => {
            if (element.idStock === data.idStock) {
              this.items[index].isActive = false
            }
          })
        })
    },
    formatearMoneda(valor) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)
      const respose = `${valorFormateado}`
      //   if (moneda === 'DOLARES') {
      //     respose = `$/. ${valorFormateado}`
      //   }
      return respose
      // Agregar el prefijo "S/." y retornar
    },
    async changeProductoFise(data) {
      console.log(data)
      const newData = { ...data.item }
      // Asignar los nuevos valores a la copia
      newData.idProducto = data.item.producto.idProducto
      newData.detalle = data.item.producto.descripcion
      newData.total = data.item.producto.precio
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', newData)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el producto', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const partComprobante = data.item.numeroComprobante.split('-')
          const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
          this.$swal({
            title: 'Anulando en la Ose',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/VENTA_DELETE'
              await store.dispatch(service, params)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    getConsultaOse(data) {
      console.info('Consulta', data)
      const params = { nro_comprobante: data.item.numero, serie: data.item.serie, tipo_documento: data.item.tipoComprobante, venta_id: 0, guia_remision_id: data.item.guiaRemisionId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, params)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    async getSearchDocumentCliente(data) {
      // this.isBusy = true
      if (data.dniBeneficiario.length < 8 || data.dniBeneficiario.length > 8) {
        this.showToast('Operación Error', 'Tiene que ingresar 8 digitos del dni', 'danger')
        return
      }
      await store
        .dispatch('comercial/CONSULTA_DNI_FIND_ALL', {
          documento: data.dniBeneficiario,
        })
        .then(async response => {
          let dataUpdate = {}
          this.items.forEach((item, index) => {
            if (item.idControlFise === data.idControlFise) {
              this.items[index].dniBeneficiario = response.data.documento
              this.items[index].nombreBeneficiario = response.data.nombreComercial
              this.items[index].direccionBeneficiario = response.data.direccion
              dataUpdate = this.items[index]
            }
          })
          await store
            .dispatch('comercial/CONTROL_FISE_UPDATE', dataUpdate)
            .then(rp => {
              console.log(rp)
              this.listarRegistros()
              this.showToast('Registro', 'Se actualizo el beneficiario', 'success')
            })
            .catch(error => {
              this.showToast('Ha ocurrido un error', error, 'danger')
            })
        })
        .catch(error => {
          this.showToast('Operación Error', error.errorMessage, 'danger')
        })
        // this.isBusy = false
    },
    async getCodigoFise(data, index) {
      if (data.codigo.length !== 11) {
        this.showToast('Operación Error', 'Tiene que ingresar 11 digitos del codigo', 'danger')
        return
      }
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.showToast('Registro', 'Se actualizo el codigo', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Ha ocurrido un error', 'EL codigo ya fue registrado', 'danger')
          this.items[index].codigo = ''
          console.log(this.items[index])
        })
    },
    async getNroComprobanteFise(tipoDocumentoId) {
      let numeroDocumento = ''
      await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: tipoDocumentoId,
      })
        .then(async response => {
          let listSerie = []
          if (response) {
            listSerie = response
          }
          let serieId = 0
          listSerie.forEach((element, index) => {
            if (index === 0) {
              serieId = element
            }
          })
          await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: tipoDocumentoId, serie_id: serieId.serieId,
          })
            .then(rp => {
              // Vue.swal.close()
              if (rp) {
                // console.log(formData.value.nro_comprobante)
                numeroDocumento = rp.nroDocumento
                // console.log(formData.value.nro_comprobante)
              }
            })
        })
      return numeroDocumento
    },
    async formParseVenta(data) {
      // console.log(data)
      const form = {
        sunatVenta: {},
        detalleVentas: [],
      }
      // idboleta = 2
      const nroDocumento = await this.getNroComprobanteFise(2)
      const sunatVenta = {
        almacen: data.origenVenta,
        clienteDato: `${data.nombreBeneficiario}|${data.direccionBeneficiario}|${data.dniBeneficiario}`,
        clienteId: 0,
        email: '',
        envio: true,
        estado: true,
        // fechaVenta: moment().format('YYYY-MM-DD'),
        fechaVencimiento: moment().format('YYYY-MM-DD'),
        formatoImpresion: 'A4',
        formatoPago: { formaPagoId: 1 },
        igv: 0,
        latitud: 'oficina',
        longitud: 'oficina',
        montoIgv: 0,
        numeroComprobante: nroDocumento,
        observacion: `VALE FISE: ${data.codigo}`,
        sub_total: data.total,
        tipoDocumento: { tipoDocumentoId: 2 },
        tipoMoneda: { tipoMonedaId: 1 },
        tipoVenta: { tipoVentaId: 2 },
        total: data.total,
        vendedorId: 0,
        ventaId: 0,
        tipoPago: 'COMPLETO',
        cantidadFise: 0,
      }
      form.sunatVenta = sunatVenta
      // items.value.forEach(element => {
      //  console.info('DETALLE ROW', element)
      const row = {
        cantidad: 1,
        detalleVentaId: 1,
        estado: true,
        igv: 0,
        observacion: '',
        precio: data.total,
        productoDato: data.detalle,
        productoId: data.idProducto,
        subTotal: data.total,
        sunatVenta: { ventaId: 0 },
        tipo: 'VENDIDO',
        unidadMedidaDato: 'NIU',
        unidadMedidaId: 0,
      }
      form.detalleVentas.push(row)
      // })
      // console.log(JSON.stringify(form))
      return (form)
    },
    async getTotalFise(data) {
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el Total', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    getProcesar(data) {
      const fise = data.item
      try {
        if (fise.codigo === '' || fise.codigo === null) {
          throw new Error('TIENE QUE INGRESAR EL CODIGO')
        }
        if (fise.dniBeneficiario === '' || fise.dniBeneficiario === null) {
          throw new Error('TIENE QUE INGRESAR EL BENEFICIARIO')
        }
        if (fise.nombreBeneficiario === '' || fise.nombreBeneficiario === null) {
          throw new Error('TIENE QUE INGRESAR EL BENEFICIARIO')
        }
        if (fise.montoFise === 0 || fise.montoFise === null) {
          throw new Error('TIENE QUE INGRESAR EL MONTO FISE')
        }
        this.$swal({
          title: 'Registrando la venta',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            this.$swal.showLoading()
            const service = 'comercial/VENTA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            const formVenta = await this.formParseVenta(fise)
            await store.dispatch(service, formVenta)
              .then(response => {
                this.$swal.close()
                this.showToast('Registro', response.message, 'success')
                fise.generaVenta = response.result
                fise.estadoFise = 'PROCESADO'
                this.getTotalFise(fise)
              })
              .catch(error => {
                this.$swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        this.$swal.close()
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    async getMontoFise(data) {
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el Total', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    calcularSubtotal() {
      let subtotal = 0
      this.itemsDetalle.forEach(value => {
        subtotal += parseFloat(value.subtotal)
      })
      this.formData.total = parseFloat(subtotal)
      this.formData.subTotal = parseFloat(subtotal)
    },
    openModalControlFise(tipo) {
      console.log(tipo)
      this.itemsDetalle = []
      // resumenControlFise
      this.selectTipoDocumento = [{ tipoDocumentoId: 1, descripcion: 'FACTURA' }, { tipoDocumentoId: 2, descripcion: 'BOLETA' }]
      this.almacen.detalleVenta.forEach(rowVentaOrigin => {
        console.log(rowVentaOrigin)
        let cantidadControl = rowVentaOrigin.cantidad
        this.resumenControlFise.forEach(element => {
          if (element.producto === rowVentaOrigin.productoId) {
            cantidadControl -= element.cantidades
          }
        })
        const row = {
          categoriaProducto: { descripcion: '' },
          codigo: '',
          codigoBarra: '',
          descripcion: rowVentaOrigin.productoDato,
          estado: true,
          idProducto: rowVentaOrigin.productoId,
          img: null,
          marcaProducto: { descripcion: '' },
          productoNombre: rowVentaOrigin.productoDato,
          tipoProductoLogistica: {},
          ubicacion: null,
          idDetallePedidoCompra: 0,
          unidadMedida: { descripcion: rowVentaOrigin.unidadMedidaDato },
          precio: parseFloat(rowVentaOrigin.precio).toFixed(2),
          cantidad: parseFloat(cantidadControl).toFixed(2),
          cantidadMax: parseFloat(cantidadControl).toFixed(2),
          igv: 0,
          flete: parseFloat(0).toFixed(2),
          precioCompra: parseFloat(Number(rowVentaOrigin.precio) + Number(0) + Number(0)).toFixed(2),
          subtotal: parseFloat(rowVentaOrigin.precio * cantidadControl).toFixed(2),
          key: (this.itemsDetalle.length) === 0 ? 0 : (this.itemsDetalle.length + 1),
          isService: false }
        if (cantidadControl > 0) {
          this.itemsDetalle.push(row)
        }
      })
      this.calcularSubtotal()
      this.formData.razon_social = 'CLIENTE VARIOS'
      this.formData.documentoCliente = '00000000'
      this.formData.direccion = '-'
      this.formData.tipoDocumento = null
      this.formData.serie = null
      this.selectClienteFinal.forEach(row => {
        if (this.almacen.clienteId === row.idCliente) {
          this.formData.clienteFinal = row
        }
      })
      // console.log(this.almacen)
      this.$refs['my-modal'].show()
    },
    async getNroComprobante() {
      await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: this.formData.tipoDocumento.tipoDocumentoId, serie_id: this.formData.serie.serieId,
      })
        .then(response => {
          // Vue.swal.close()
          if (response) {
            // console.log(formData.value.nro_comprobante)
            this.formData.nro_comprobante = response.nroDocumento
            // console.log(formData.value.nro_comprobante)
          }
        })
    },
    async getSeries() {
      await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: this.formData.tipoDocumento.tipoDocumentoId,
      })
        .then(response => {
          if (response) {
            this.selectSerie = response
          }
          this.selectSerie.forEach((element, index) => {
            if (index === 0) {
              this.formData.serie = element
            }
          })
          this.getNroComprobante()
        })
    },
    async getSearchDocument(tDocumento) {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: this.formData.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(response => {
          console.log(response)
          this.formData.razon_social = response.data.razonSocial
          this.formData.direccion = response.data.direccion
        })
        .catch(error => {
          console.log(error)
          this.showToast('Ha ocurrido un error', error.message, 'danger')
        })
      // this.isBusy = false
    },
    async createControlFise(genera) {
      // CONTROL_FISE_CREATE
      const data = {
        codigo: '',
        detalle: '',
        direccionBeneficiario: this.formData.direccion,
        dniBeneficiario: this.formData.documentoCliente,
        estado: true,
        estadoFise: 'PROCESADO',
        // fecha: moment().format('YYYY-MM-DD'),
        generaVenta: genera,
        idProducto: 0,
        montoFise: 0,
        nombreBeneficiario: this.formData.razon_social,
        origenVenta: this.almacen,
        tipo: 'FACTURACION',
        total: 0,
      }
      await store
        .dispatch('comercial/CONTROL_FISE_CREATE', data)
        .then(rp => {
          console.log(rp)
          this.$refs['my-modal'].hide()
          this.listarRegistrosFacturacion()
          this.showToast('Registro', 'Se registro el contril Fise', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    onSubmit() {
      try {
        const form = {
          sunatVenta: {},
          detalleVentas: [],
        }
        // idboleta = 2
        console.log(this.formData)
        const nroDocumento = this.formData.nro_comprobante
        const sunatVenta = {
          almacen: this.almacen.almacen,
          clienteDato: `${this.formData.razon_social}|${this.formData.direccion}|${this.formData.documentoCliente}`,
          clienteId: 0,
          email: '',
          envio: true,
          estado: true,
          fechaVenta: '',
          fechaVencimiento: moment().format('YYYY-MM-DD'),
          formatoImpresion: 'A4',
          formatoPago: { formaPagoId: 1 },
          igv: 0,
          latitud: 'oficina',
          longitud: 'oficina',
          montoIgv: 0,
          numeroComprobante: nroDocumento,
          observacion: this.formData.observacion,
          sub_total: this.formData.total,
          tipoDocumento: this.formData.tipoDocumento,
          tipoMoneda: { tipoMonedaId: 1 },
          tipoVenta: { tipoVentaId: 2 },
          total: this.formData.total,
          vendedorId: 0,
          ventaId: 0,
          tipoPago: 'COMPLETO',
          cantidadFise: 0,
          clienteFinal: this.formData.clienteFinal,
        }
        form.sunatVenta = sunatVenta
        this.itemsDetalle.forEach(element => {
          console.info('DETALLE ROW', element)
          const row = {
            cantidad: element.cantidad,
            detalleVentaId: 1,
            estado: true,
            igv: 0,
            observacion: '',
            precio: element.precio,
            productoDato: element.productoNombre,
            productoId: element.idProducto,
            subTotal: element.subtotal,
            sunatVenta: { ventaId: 0 },
            tipo: 'VENDIDO',
            unidadMedidaDato: element.unidadMedida.descripcion,
            unidadMedidaId: 0,
          }
          form.detalleVentas.push(row)
        })
        this.$swal({
          title: 'Registrando la venta',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            this.$swal.showLoading()
            const service = 'comercial/VENTA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, form)
              .then(response => {
                this.$swal.close()
                this.showToast('Registro', response.message, 'success')
                // CREAR NUEVO CONTROL FISE
                this.createControlFise(response.result)
                // fise.generaVenta = response.result
                // fise.estadoFise = 'PROCESADO'
                // this.getTotalFise(fise)
              })
              .catch(error => {
                this.$swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        this.$swal.close()
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    changeCantidadPrecio(row, tipo) {
      console.log(tipo)
      this.itemsDetalle.forEach((element, index) => {
        if (element.key === row.key) {
          this.itemsDetalle[index].subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
        }
      })
      this.calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    },
    deleteDetalleFacturacion(data) {
      console.log(data)
      this.itemsDetalle.forEach((value, index) => {
        console.log(value)
        if (data.idProducto === value.idProducto) {
          this.itemsDetalle.splice(index, 1)
        }
      })
      this.calcularSubtotal()
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }

  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
